import { useEffect } from 'react';
import emitter from '../../../utils/clients/mott';
import createTrackingEvents, {
  trackingDataBase,
  pageMap,
  sanitizeDataset,
  fireOnce,
} from '../helpers/events.helpers';
import type {
  ImpressionTrackerElement,
  TrackerElementEvent,
  TrackingEvent,
} from '../interfaces/analytics.interface';
import {
  IMPRESSION,
  SCROLL,
  CLICK,
  CHANGE,
  DASHBOARD,
  ORGANIZER,
  TRACK_TYPEAHEAD_INTERACTION,
  ONBOARDING,
  TRACK_MODAL_JOB_COMPLETION_INTERACTION,
  WEDDING_VISION,
  OUR_PROFILE,
} from '../constants/analytics.constants';

export type AnalyticsPageType =
  | typeof DASHBOARD
  | typeof ORGANIZER
  | typeof ONBOARDING
  | typeof WEDDING_VISION
  | typeof OUR_PROFILE;

export function useAnalytics(page: AnalyticsPageType) {
  // For development purposes only, to prevent error due to missing window value from GTM script
  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    window.XO = window.XO || {};
    window.XO.ads = window.XO.ads || {};
    window.XO.ads.gpt = { addAsyncSlot: () => {}, renderAds: () => {} };
  }

  // setup impressions listener
  useEffect(() => {
    emitter.on(
      IMPRESSION,
      function createImpressionEmitter(trackingElement: ImpressionTrackerElement) {
        const { dataset } = trackingElement;
        const { trackerModule, trackerKey, ...rest } = dataset;

        function impressionCallback() {
          trackInteraction({
            ...trackingDataBase(page),
            name: pageMap[page].impression,
            module: trackerModule,
            ...sanitizeDataset(rest),
          });
        }
        const eventKeyForSessionStorage = trackerKey || trackerModule;
        fireOnce(eventKeyForSessionStorage, impressionCallback);
      }
    );
    emitter.on(
      TRACK_TYPEAHEAD_INTERACTION,
      function createTypeaheadInteractionEmitter(ev: TrackerElementEvent) {
        const { target } = ev;
        const { dataset } = target;
        const { trackerSource, trackerHeader, ...rest } = dataset;

        function typeaheadInteractionCallback() {
          trackInteraction({
            ...trackingDataBase(page),
            name: pageMap[page].interaction,
            source: trackerSource,
            header: trackerHeader,
            ...sanitizeDataset(rest),
          });
        }
        const eventKeyForSessionStorage = `${trackerSource}_${trackerHeader
          ?.split(' ')
          ?.join('_')}`;
        fireOnce(eventKeyForSessionStorage, typeaheadInteractionCallback);
      }
    );

    emitter.on(
      TRACK_MODAL_JOB_COMPLETION_INTERACTION,
      function createModalJobCompletionInteractionEmitter(ev: TrackerElementEvent) {
        const { target } = ev;
        const { dataset, textContent } = target;

        function modalJobCompletionInteractionCallback() {
          trackInteraction({
            ...trackingDataBase(page),
            name: pageMap[page].interaction,
            action: TRACK_MODAL_JOB_COMPLETION_INTERACTION,
            text: textContent && textContent !== '' ? textContent : target?.tagName,
            ...sanitizeDataset(dataset),
          });
        }
        const eventKeyForSessionStorage = `${TRACK_MODAL_JOB_COMPLETION_INTERACTION}_${dataset?.trackerJobName}`;
        fireOnce(eventKeyForSessionStorage, modalJobCompletionInteractionCallback);
      }
    );

    // setup all other events listeners
    createTrackingEvents(page).forEach(function createEmitter(trackingEvent) {
      emitter.on(
        trackingEvent.trackingEventName,
        async function trackEvent(ev: TrackerElementEvent) {
          const { target } = ev;
          const { dataset, tagName, textContent } = target;
          const isAnchor = tagName === 'A';
          const openInNewTab =
            ev.ctrlKey || ev.metaKey || target.getAttribute('target') === '_blank';
          const href = target.getAttribute('href') || null;
          const {
            useInternalRouter,
            tracker,
            useFireOnce,
            sessionStorageKey = '',
            trackerModule = '',
            ...rest
          } = dataset;

          if (isAnchor && !useInternalRouter) {
            ev.preventDefault();
          }

          const trackingData = {
            ...trackingEvent.data,
            action: tracker,
            selection: href,
            module: trackerModule,
            text: textContent && textContent !== '' ? textContent : target?.tagName,
            ...sanitizeDataset(rest),
          };
          try {
            if (useFireOnce) {
              fireOnce(sessionStorageKey, () => trackInteraction(trackingData));
            } else {
              await trackInteraction(trackingData);
            }
          } catch (error) {
            console.warn(error);
          } finally {
            if (isAnchor && href && !useInternalRouter) {
              if (openInNewTab) {
                return window.open(href, '_blank');
              }

              return (window.location.href = href);
            }
            return;
          }
        }
      );
    });

    document.addEventListener<any>(SCROLL, checkForImpressions, false);
    document.addEventListener<any>(CLICK, checkForEvents, false);
    document.addEventListener<any>(CHANGE, checkForEvents, false);
    return function analyticsEffectCleanup() {
      document.removeEventListener<any>(SCROLL, checkForImpressions, false);
      document.removeEventListener<any>(CLICK, checkForEvents, false);
      document.removeEventListener<any>(CHANGE, checkForEvents, false);
    };
  }, []);
}

function checkForImpressions() {
  const impressionTrackers = document.querySelectorAll<ImpressionTrackerElement>(
    '[data-impression-tracker]'
  );

  impressionTrackers.forEach(function checkForImpression(el: ImpressionTrackerElement) {
    if (isInViewport(el)) {
      emitter.emit(IMPRESSION, el);
    }
  });
}

function checkForEvents(ev: TrackerElementEvent) {
  const { target } = ev;
  if (target?.dataset.tracker) {
    emitter.emit(target.dataset.tracker, ev);
  }
}

export function trackInteraction(data: TrackingEvent['data']) {
  let retries = 0;
  return new Promise((resolve, reject) => {
    try {
      function waitForAnalytics() {
        if (window.analytics) {
          window.analytics.track(data.name, data, {}, () => resolve('success'));
        } else if (retries < 3) {
          retries += 1;
          setTimeout(() => waitForAnalytics(), 500);
        } else {
          reject('Analytics object not present on window.');
        }
      }
      waitForAnalytics();
    } catch (error) {
      reject(error);
    }
  });
}

function isInViewport(el: ImpressionTrackerElement) {
  const rect = el.getBoundingClientRect();

  return (
    rect.bottom >= 0 &&
    rect.left >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
